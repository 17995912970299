.zoomable-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  
  .controls {
    display: flex;
    gap: 16px;
  }
  
  .controls button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .image-container {
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
