:root {
  --hr-divider: #666666;
  --col-divider: rgba(0, 0, 0, 0.12);
}

/* Custom Scroll */
* {
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  width: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  /*border-right: 3px solid rgba(255, 255, 255, 0.8);*/
}


/* Common Css  */
.MuiPaper-root {
  padding: 0px !important;
  margin: 0px !important;
}

.fullHeightRight,
.fullHeightLeft {
  height: calc(100vh - 0px) !important;
}

.fullHeightRight {
  background-color: #F9F9F9;
}

.fullHeightLeft {
  background-color: #E3EEFB;
  border-right: 1px solid var(--col-divider);
}

.RightMessageCol {
  height: 100vh !important;
}


/* Footer */
.appFooter {
  padding: 0px !important;
  background: #FFF;
  pointer-events: none;
  border-top: 1px solid #eee;
}

.appFooter .MuiList-padding {
  padding: 0px !important;
}

.appFooter .MuiTypography-body1 {
  text-align: center;
}






/* Conversations User List */
.conversations-list {
  background-color: #F9F9F9;
  height: 95vh;
  border-right: 1px solid var(--col-divider);
}

.conversations-list hr {
  border-color: var(--hr-divider);
  margin-bottom: 0px;
  border-bottom-width: 1px;
}

/* Conversations Title + Count */
.conversations-list h4,
.conversations-counts .MuiTypography-h4 {
  color: #073366;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 15px;
  padding: 0px;
}

.conversations-list h4 span {
  font-weight: normal;
  font-size: 18px;
}

/* Search */
.conversations-list .search {
  background: #e7f2ff;
  padding: 5px;
  margin: 10px 0px 0px 0px;
  border-radius: 8px;
}

.conversations-list .search input,
.conversations-list .search ::placeholder {
  font-size: 14px;
}

.conversations-list .search ::placeholder {
  color: #073366;
  opacity: 0.5;
}

.conversations-list .search button {
  color: #073366;
}

/* Conversations User List */
.conversations-list .css-1m66g3u:nth-of-type(2) {
  padding: 0px;
}

.conversations-list ul.user-list {
  padding: 0px;
  height: calc(100vh - 130px);
  overflow-y: auto;
}

/*calc(100vh - 159px)*/
.conversations-list ul.user-list .MuiListItem-padding {
  border-left: 4px solid rgba(255, 255, 255, 0);
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.conversations-list ul.user-list .MuiListItem-padding:hover {
  background: #e7f2ff;
}

.conversations-list ul.user-list .selected {
  border-left: 4px solid #1180ff;
  background: #e7f2ff;
}

/* List Item */
.conversations-list ul.user-list .MuiListItem-padding .MuiListItemText-multiline {
  position: relative;
  margin: 0px;
}

.conversations-list ul.user-list .MuiListItem-padding .MuiListItemText-multiline span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

/* Avatar */
.conversations-list ul.user-list .MuiListItem-padding .MuiListItemIcon-root {
  min-width: 52px;
}

.conversations-list ul.user-list .MuiListItem-padding .MuiListItemIcon-root .MuiBadge-overlapCircular {
  border: 2px solid #fff !important;
}

/* User info */
.conversations-list ul.user-list .MuiListItem-padding .MuiListItemText-root:last-child {
  margin: 0px !important;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
}

.conversations-list ul.user-list .MuiListItem-padding .MuiListItemText-root:last-child span {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.6;
}

.conversations-list ul.user-list .MuiListItem-padding .MuiListItemText-root:last-child p {
  font-size: 12px;
  font-weight: 300;
}


/* Chat Box */
.chatMessageBox {
  background-color: #ffffff !important;
  border-right: 1px solid var(--col-divider);
  border-left: 1px solid var(--col-divider);
}

.chatMessageBox hr {
  border-color: var(--hr-divider);
  margin-bottom: 0px;
  border-bottom-width: 1px;
}



.chatMessageBox .header-info>.MuiListItem-root {
  margin-top: 12px;
  margin-bottom: 12px;
}

.chatMessageBox .header-info .css-konndc-MuiListItemText-root .MuiTypography-body1 {
  font-size: 1.125rem;
  font-weight: 500;
}

.chatMessageBox .header-info .css-cveggr-MuiListItemIcon-root {
  min-width: 75px;
}

/* Chat Box - Message Content */
.messageContent {
  background: #f5f5f5;
  padding: 0px !important;
  display: flex;
  height: calc(100vh - 140px);
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
}

.messageContent .nav-tabs * {
  border-radius: 0px !important;
}

.messageContent .attached-File {
  border: 1px solid #ececec;
  background: #fefff4;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
}

.messageContent .attached-File .MuiSvgIcon-root {
  margin-left: 10px;
  fill: red;
  cursor: pointer;
}



.attachedFile-section {
  padding: 10px 20px 0px 20px !important;
  display: block !important;
}

.messageContent .nav-tabs li:first-child button {
  border-left: none !important;
  border-top: none !important;
}

.messageContent .nav-tabs li:last-child button {
  color: #ff9113 !important;
}

.messageContent .nav-tabs li:last-child button.active {
  background: #fffbf6;
}

.messageContent #controlled-tab-example-tabpane-note {
  background-color: #fffbf6 !important;
  padding-bottom: 15px;
}

.messageContent #controlled-tab-example-tabpane-reply {
  padding-bottom: 15px;
}

.messageContent .nav-tabs button {
  border-top: none !important;
}

.messageContent .tab-pane fieldset {
  border-color: rgba(0, 0, 0, 0.1);
}

.messageContent .chatButtons {
  margin: 0px;
  padding: 0px;
}

.messageContent .chatButtons span {
  background-color: #FFE7CC !important;
  padding: 6px 8px !important;
  border-radius: 8px !important;
  margin-right: 10px !important;
  cursor: pointer !important;
  display: inline-table !important;
  line-height: normal !important;
}

.messageContent .chatButtons span svg {
  cursor: pointer !important;
}

.messageContent #controlled-tab-example-tabpane-reply .chatButtons span {
  background-color: #e9e9e9 !important;
}

/* Chat Header*/
.chat-user-name {
  text-transform: capitalize !important;
}

/* Chat Box - Chat Dashboard */
/* .chatDashboard {
  overflow: ;
} */

/* Conversations Counts */
.conversations-counts .MuiList-root .MuiListItemButton-root {
  color: #073366;
}

.conversations-counts hr.MuiDivider-fullWidth {
  border-color: var(--hr-divider);
  margin-bottom: 0px;
  border-bottom-width: 1px;
}

.conversations-counts .MuiList-root .MuiListItemButton-root:hover {
  background: #cfe6ff;
  color: #1180ff;
  border-radius: 8px;
}

.conversations-counts .MuiList-root .MuiListItemButton-root:hover .MuiListItemIcon-root {
  color: #1180ff;
}

.conversations-counts .MuiList-root .selected {
  background: #cfe6ff;
  color: #1180ff;
  border-radius: 8px;
}

.conversations-counts .MuiList-root .selected .MuiListItemIcon-root {
  color: #1180ff;
}

.conversations-counts .MuiList-root .MuiListItemIcon-root {
  min-width: auto;
  font-weight: normal;
  color: #073366;
}


/* Live Agent Version block */
.LiveAgentVersion {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.LiveAgentVersion .MuiListItemButton-gutters {
  color: #073366;
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 25px 0px 10px 0px;
  pointer-events: none;
}

.LiveAgentVersion .MuiListItemButton-gutters:hover {
  background-color: transparent !important;
}




.css-wg4wlj-MuiButtonBase-root-MuiListItemButton-root.active {
  background-color: #f7f7f7;
  margin: 10px 5px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}

.whiteColor {
  color: #fff !important;
  text-decoration: none;
}


.css-wg4wlj-MuiButtonBase-root-MuiListItemButton-root.active {
  background-color: #CFE6FF !important;
}


.copy-text {
  font-size: 12px !important;
  cursor: pointer;
  margin-left: auto;
  font-weight: bold;
  opacity: "1",
}

.copy-text-10 {
  font-size: 9px !important;
  cursor: pointer;
  margin-left: auto;
  font-weight: bold;

}

/* .suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 2px;
} */



.suggestion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}

.suggestion-item:hover {
  background-color: #c9eebc;
  color: #000;
}

.canned-key {
  font-size: 15px;
  font-weight: bold;
}

.canned-content {
  font-size: 12px;
  margin-left: 5px;
}

/* VoiceNote.css */
.voice-note-container {
  background-color: #000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  color: #fff!important;
}

.voice-note-container .btn {
  background-color: #1180FF;
  border: none;
  color: white;
  width: 72px; /* Equal width and height to make a perfect circle */
  height: 72px; 
  font-size: 16px;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.voice-note-container .btn:hover {
  background-color: #2980b9;
}

.voice-note-container .btn.stop {
  background-color: #e74c3c;
}

.voice-note-container .btn.stop:hover {
  background-color: #c0392b;
}

.voice-note-container .audio-container {
  margin-top: 20px;
  text-align: center;
  width: 100%;
 
}

.voice-note-container .audio-container  .buttonContainer
{
  margin-top: 15px;
  
}

.voice-note-container .custom-audio-player {
  background: #000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  padding: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voice-note-container .styled-audio-player {
  width: 100%;
  border-radius: 50px;
  outline: none;
  background: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.voice-note-container audio::-webkit-media-controls-panel {
  background-color: #3498db;
  border-radius: 5px;
}

.voice-note-container audio::-webkit-media-controls-play-button,
.voice-note-container audio::-webkit-media-controls-timeline {
  filter: brightness(0) invert(1);
}

.voice-note-container audio::-webkit-media-controls-mute-button,
.voice-note-container audio::-webkit-media-controls-volume-slider-container {
  filter: brightness(0) invert(1);
}

.voice-note-container audio::-webkit-media-controls-volume-slider {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

/* recording-status */
.voice-note-container .recording-status {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color:#fff !important;
}


.voice-note-container .recording-status span {
  color:#fff !important;
}
.voice-note-container .blink-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 10px;
  animation: blink 1s infinite;
}

.voice-note-container .btnReset {
  background-color: #1c2126 !important;
  border: none;
  color: #b6b8ba;
  cursor: pointer;
  margin: 0px 5px !important;
  border-radius: 50% !important;
  padding: 8px!important;
}

.voice-note-container .btnSend {
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0px 5px !important;
  padding: 5px;
  border-radius: 18px !important;
  padding: 6px 20px !important;
}

.voice-note-container .recordedAudio {
  display: initial;
  background: #141414;
  padding: 5px 10px 5px 10px;
  border-radius: 5px 5px 0px 0px;
  color: #b6b8ba;
  box-shadow: 0 1px 1px 2px rgba(255, 255, 255, 0.02) inset;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}



/* .audio-player{
  background: #181818;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  padding: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-player audio::-webkit-media-controls-panel {
  background-color: #3498db;
  border-radius: 5px;
}


.audio-player audio::-webkit-media-controls-play-button,
.audio-player audio::-webkit-media-controls-timeline {
  filter: brightness(0) invert(1);
}

.audio-player audio::-webkit-media-controls-mute-button,
.audio-player audio::-webkit-media-controls-volume-slider-container {
  filter: brightness(0) invert(1);
}

.audio-player audio::-webkit-media-controls-volume-slider {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
} */

.audio-player {
  background: #181818;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 2px 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Force background color and remove any border/shadow for audio element itself */
.audio-player audio {
  background-color: #181818 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Chrome-specific: Remove background, border, and shadow on controls panel */
.audio-player audio::-webkit-media-controls-panel {
  background-color: #181818 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
}

/* Firefox-specific: Ensure consistent background for controls panel */
.audio-player audio::-moz-media-controls-panel {
  background-color: #181818 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Style play, mute, and timeline controls to match dark theme */
.audio-player audio::-webkit-media-controls-play-button,
.audio-player audio::-webkit-media-controls-mute-button,
.audio-player audio::-webkit-media-controls-timeline,
.audio-player audio::-webkit-media-controls-volume-slider-container {
  filter: brightness(0) invert(1) !important;
}

/* Volume slider styling */
.audio-player audio::-webkit-media-controls-volume-slider {
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

/* Additional cross-browser consistency for progress and volume */
.audio-player audio::-webkit-media-controls-timeline,
.audio-player audio::-webkit-media-controls-current-time-display,
.audio-player audio::-webkit-media-controls-time-remaining-display {
  color: #ffffff !important;
}

/* Final layer of control area styling */
.audio-player audio::-webkit-media-controls {
  background-color: #181818 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}



/* ChatMediaDialog */

.ChatMediaDialog{
  padding: 5px;
  border-radius: 15px !important;
  overflow: hidden;
}

.ChatMediaDialog .ChatMediaDialog-header{
  padding: 15px 5px!important;
}

.ChatMediaDialog .ChatMediaDialog-body{
  padding: 0px 5px!important;
}

.ChatMediaDialog .ChatMediaDialog-footer{
  padding: 2px!important;
}



/* audio::-webkit-media-controls-panel {
     background-color: #000!important;
   
     } */